@font-face {
  font-family: Icons-Regular;
  src: url("fa-regular-400.609f9c4c.woff2");
}

html, body {
  background-color: #fff;
}

.borderless {
  border: none !important;
}

.full-height, #app {
  height: 100%;
}

.ui.grid > .stretched.row > .column > .header {
  flex: 0;
}

.ct-series-online {
  stroke: #21ba45;
}

.ct-series-offline {
  stroke: #db2828;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .ui.alert.feed {
    overflow-y: auto;
  }
}

@media only screen and (min-width: 992px) {
  [class*="tablet or lower visible"] {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.doubling.grid > .row > .column {
    display: flex !important;
  }
}

i.group.icon:before {
  display: inline;
}

/*# sourceMappingURL=index.e2877a13.css.map */
